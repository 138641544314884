import React, { useContext } from 'react'
import './DashboardNavbar.scss'
import DashboardNavItem from '../DashboardNavItem/DashboardNavItem'
import {CiSearch} from "react-icons/ci"
import AuthContext from '../../../context/authContext'
import { SlLogout } from 'react-icons/sl'
import { AiOutlineBell } from 'react-icons/ai'
import { asstAdminNavItems, hoaNavItems, NavItems, StudentNavItems, TeacherNavItems } from '../../../utils/data'


const DashboardNavbar = () => {
    const {portalMobileView, logoutUser, user} = useContext(AuthContext);
    

  return (
    <div id='dashboard-navbar'>
        <div className="dashboard-navigations">
            <ul className={`${portalMobileView && "active-navbar"} dashboard-menu-list `}>
                {
                    user?.is_administrator && (
                        <div className='nav-items-menu-list'>
                            {
                                NavItems.map((item, index) => (
                                    <DashboardNavItem item={item} key={index}/>
                                ))
                            }
                        </div>
                    )
                }
                {
                    user?.is_employee && (
                        <div>
                            {
                                user?.position === "Teacher" && (
                                    <div className='nav-items-menu-list'>
                                        {
                                            TeacherNavItems.map((item, index) => (
                                                <DashboardNavItem item={item} key={index}/>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {
                                user?.position === "Assistant Administrator" && (
                                    <div className='nav-items-menu-list'>
                                        {
                                            asstAdminNavItems.map((item, index) => (
                                                <DashboardNavItem item={item} key={index}/>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {
                                user?.position === "Head Of Academics" && (
                                    <div className='nav-items-menu-list'>
                                        {
                                            hoaNavItems.map((item, index) => (
                                                <DashboardNavItem item={item} key={index}/>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    user?.is_student && (
                        <div className='nav-items-menu-list'>
                            {
                                StudentNavItems?.map((item, index) => (
                                    <DashboardNavItem item={item} key={index}/>
                                ))
                            }
                        </div>
                    )
                }
                
                {
                    portalMobileView && (
                        <div className="notification-user-logout">
                            <div className="notification">
                            <AiOutlineBell />
                            <div className="notification-dot"></div>
                            </div>
                            <div className="user">
                            <div className="username-position">
                                <p className="username">
                                {user.first_name} {user.last_name}
                                </p>
                                <p className="position">
                                Admin
                                </p>
                            </div>
                            <div className="user-image">

                            </div>
                            </div>
                            <div className="logout-btn" onClick={logoutUser}>
                            <SlLogout /> Logout
                            </div>
                        </div>
                    )
                }
            </ul>
            
        </div>
        {/* <div className="dashboard-navbar-search">
            <CiSearch />
            <input type="text" name="" id="" placeholder='Search' />
        </div> */}
    </div>
  )
}

export default DashboardNavbar