import React, { useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import axios from "axios";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const AddStaff = () => {
  const navigations = ["Dashboard", "User Management", "Add Staff"];
  const [staffData, setStaffData] = useState(null);
  const [creatingEmployee, setCreatingEmployee] = useState(false);

  const handleFormChange = (e) => {
    setStaffData({
      ...staffData,
      [e.target.name]: e.target.value,
    });
  };

  const createStaff = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(staffData);

    await axios
      .post(`${api_url}accounts/employee/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Employee Created Successfully",
        }).then(() => {
          setCreatingEmployee(false);
          window.location.href = "/admin/user-management/staffs";
        });
      })
      .catch((error) => {
        console.error(error);
        setCreatingEmployee(false);
        if(error?.response?.data){
            for (const property in error?.response?.data){
                if(property === "0"){
                    toast.error(`${error?.response?.data[property]}`)
                }else{
                    toast.error(`${property} error: ${error?.response?.data[property]}`)
                }
            }
        }else{
            toast.error("Something unexpected happended. Please try again.")
        }
      });
  };

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Add New Staff</h3>
        </div>
        <form onSubmit={(e) => createStaff(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Employee ID</label>
              <input
                type="text"
                name="employee_id"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">First Name*</label>
              <input
                type="text"
                name="first_name"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
                required
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Last Name*</label>
              <input
                type="text"
                name="last_name"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
                required
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Position*</label>
              <select
                onChange={(e) => handleFormChange(e)}
                name="position"
                id=""
                className="custom-form-control"
                required
              >
                <option value="">Select Position</option>
                <option value="Teacher">Teacher</option>
                <option value="Assistant Administrator">Assistant Administrator</option>
                <option value="Head Of Academics">Head Of Academics</option>
              </select>
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>

            <div className="custom-form-col">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="email"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>

            <div className="custom-form-col">
              <label htmlFor="">Nationality</label>
              <input
                type="text"
                name="nationality"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Marital Status</label>
              <select
                name="marital_status"
                onChange={(e) => handleFormChange(e)}
                id=""
                className="custom-form-control"
              >
                <option value="">Select Marital Status</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
              </select>
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Date of birth</label>
              <input
                type="date"
                name="date_of_birth"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Hometown</label>
              <input
                type="text"
                name="hometown"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Username*</label>
              <input
                type="text"
                name="username"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
                required
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Password*</label>
              <input
                type="text"
                name="password"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
                required
              />
            </div>
          </div>
          <div className="form-action-buttons">
            {creatingEmployee ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStaff;
