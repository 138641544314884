import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import { api_url } from "../../../url";
import parse from "html-react-parser";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";

const StudentAssignmentDetails = () => {
  const { assignment_id } = useParams();
  const [loadingAssignmentDetails, setLoadingAssignmentDetails] =
    useState(true);
  const [assignmentDetails, setAssignmentDetails] = useState({});
  const [submittingAssignment, setSubmittingAssignment] = useState(false);
  const [submissionData, setSubmissionData] = useState({});

  const navigations = ["Dashboard", "Academics", "Assignment Details"];

  const getAssignmentDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}student/assignments/${assignment_id}/`, config)
      .then((response) => {
        setLoadingAssignmentDetails(false);
        if (response.data?.success) {
          setAssignmentDetails(response.data?.payload);
        } else {
          toast.error("Failed to get assignments");
        }
      })
      .catch((error) => {
        toast.error("Something unexpected happened.");
      });
  };

  const submitAssignment = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const formData = new FormData();

    Object.keys(submissionData).forEach((key) => {
      formData.append(key, submissionData[key]);
    });

    await axios
      .put(`${api_url}student/assignments/${assignment_id}/`, formData, config)
      .then((response) => {
        setSubmittingAssignment(false);
        if (response.data?.success) {
          Swal.fire({
            title: "Assignment Submitted",
            text: "Your assignment has been submitted successfully",
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          if (response?.data?.error) {
            for (const property in response?.data?.error) {
              if (property === "0") {
                toast.error(`${response?.data?.error[property]}`);
              } else {
                toast.error(
                  `${property} error: ${response?.data?.error[property]}`
                );
              }
            }
          } else {
            toast.error("Something unexpected happended. Please try again.");
          }
        }
      })
      .catch((error) => {
        setSubmittingAssignment(false);
        toast.error("Something unexpected happened.");
      });
  };

  useEffect(() => {
    getAssignmentDetails();
  }, []);

  return (
    <div>
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Assignment Details</div>
        </div>
        {loadingAssignmentDetails ? (
          <Loading />
        ) : (
          <div>
            <div className="custom-form" style={{ marginBottom: "20px" }}>
              <div className="custom-form-row">
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Title
                  </label>
                  <p>{assignmentDetails?.class_assignment?.title}</p>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Class
                  </label>
                  <p>{assignmentDetails?.class_assignment?.school_class}</p>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Subject
                  </label>
                  <p>{assignmentDetails?.class_assignment?.subject}</p>
                </div>
              </div>
              <div className="custom-form-row">
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Due Date
                  </label>
                  <p>{assignmentDetails?.class_assignment?.due_date}</p>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    File Attachment
                  </label>
                  <Link
                    style={{ textDecoration: "underline", color: "blue" }}
                    to={`${api_url}${assignmentDetails?.class_assignment?.attachment}`}
                    download
                    target="_blank"
                  >
                    Download File
                  </Link>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Uploaded by
                  </label>
                  <p>{assignmentDetails?.class_assignment?.uploaded_by}</p>
                </div>
              </div>
              <div className="custom-form-row">
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Description
                  </label>
                  {
                    assignmentDetails?.class_assignment?.description && (
                      <p>
                        {parse(assignmentDetails?.class_assignment?.description)}
                      </p>
                    )
                  }
                </div>
              </div>
            </div>
            <hr />
            {assignmentDetails?.status === "Pending Submission" && (
              <div>
                <p
                  style={{
                    textAlign: "center",
                    padding: "5px 10px",
                    border: "1px solid gray",
                    marginBlock: "20px",
                    borderRadius: "10px",
                  }}
                >
                  You have not submitted your assignment yet. Upload your
                  assignment document and click on the upload button to submit
                  the assignment
                </p>
                <form
                  onSubmit={(e) => submitAssignment(e)}
                  className="custom-form"
                >
                  <div className="custom-form-row">
                    <div
                      className="custom-form-col"
                      style={{ width: "max-content" }}
                    >
                      <label htmlFor="">Select File*</label>
                      <input
                        required
                        type="file"
                        name="submission_file"
                        onChange={(e) =>
                          setSubmissionData({
                            ...submissionData,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="form-action-buttons"
                    style={{ justifyContent: "start" }}
                  >
                    {submittingAssignment ? (
                      <div style={{ textAlign: "center" }}>
                        <RotatingLines
                          strokeColor="#021D53"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="26"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="primary-button w-max-content"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
            {assignmentDetails?.status === "Submitted" && (
              <div>
                <p
                  style={{
                    textAlign: "center",
                    padding: "5px 10px",
                    border: "1px solid gray",
                    marginBlock: "20px",
                    borderRadius: "10px",
                  }}
                >
                  You have submitted your assignment successfuly
                </p>
                <div className="custom-form">
                  <div className="custom-form-row">
                    <div className="custom-form-col">
                      <label
                        style={{ fontWeight: 700, fontSize: "20px" }}
                        htmlFor=""
                      >
                        Submission Date
                      </label>
                      <p>{assignmentDetails?.submission_date}</p>
                    </div>
                    <div className="custom-form-col">
                      <label
                        style={{ fontWeight: 700, fontSize: "20px" }}
                        htmlFor=""
                      >
                        Submission File
                      </label>
                      <Link
                        style={{ textDecoration: "underline", color: "blue" }}
                        to={`${api_url}${assignmentDetails?.submission_file}`}
                        download
                        target="_blank"
                      >
                        Download File
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentAssignmentDetails;
