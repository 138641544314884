import React, { useEffect, useState } from "react";
import UserImage from "../../assets/admissionimages/dummyProfile.png";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ImUpload3 } from "react-icons/im";

const StudentDetails = ({ setActiveStep, handleFormChange, data, setData }) => {
  const location = useLocation();
  const [passportPicture, setPassportPicture] = useState(null);

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 220);
    if (data.passport_picture) {
      createPassportPicture(data.passport_picture);
    }
  }, [location]);

  const handleFileUpload = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.files[0],
    });
  };

  const saveAndExit = () => {
    window.location.href = "/admission";
  };
  const saveAndProceed = () => {
    const validated_fields = validateFields();
    
    if (validated_fields) {
      setActiveStep(2);
    }
    // setActiveStep(2)
  };

  // GETTING YEARS
  var currentYear = new Date().getFullYear();
  var years = [];
  var startYear = 2000;
  for (var i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

  // GETTING MONTHS
  const months = [
    {
      name: "January",
      value: "01",
    },
    {
      name: "February",
      value: "02",
    },
    {
      name: "March",
      value: "03",
    },
    {
      name: "April",
      value: "04",
    },
    {
      name: "May",
      value: "05",
    },
    {
      name: "June",
      value: "06",
    },
    {
      name: "July",
      value: "07",
    },
    {
      name: "August",
      value: "08",
    },
    {
      name: "September",
      value: "09",
    },
    {
      name: "October",
      value: "10",
    },
    {
      name: "November",
      value: "11",
    },
    {
      name: "December",
      value: "12",
    },
  ];

  // GETTING DAYS
  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  // GETTING REGIONS
  const regions = [
    "AHAFO",
    "ASHANTI",
    "BONO EAST",
    "BRONG AHAFO",
    "CENTRAL",
    "EASTERN",
    "GREATER ACCRA",
    "NORTH EAST",
    "NORTHERN",
    "OTI",
    "SAVANNAH",
    "UPPER EAST",
    "UPPER WEST",
    "WESTERN",
    "WESTERN NORTH",
    "VOLTA",
  ];

  // VALIDATE FIELDS
  const validateFields = () => {
    if (
      data.passport_picture === "" ||
      data.passport_picture === undefined ||
      data.passport_picture === null
    ) {
      toast.error("Passport picture is required");
      return false;
    } else if (data.surname === "") {
      toast.error("Surname is required");
      return false;
    } else if (data.first_name === "") {
      toast.error("First Name is required");
      return false;
    } else if (data.gender === "") {
      toast.error("Gender is required");
      return false;
    } else if (
      data.dob_day === "" ||
      data.dob_month === "" ||
      data.dob_year === ""
    ) {
      toast.error("Date of Birth is required");
      return false;
    } else if (data.nationality === "") {
      toast.error("Nationality is required");
      return false;
    } else if (data.hometown === "") {
      toast.error("Hometown is required");
      return false;
    } else if (data.home_address === "") {
      toast.error("Home Address is required");
      return false;
    } else if (data.region === "") {
      toast.error("Region is required");
      return false;
    } else if (data.home_language === "") {
      toast.error("Home Language is required");
      return false;
    } else if (data.religion === "") {
      toast.error("Religion is required");
      return false;
    } else if (data.applying_class === "") {
      toast.error("Class is required");
      return false;
    } else if (data.previous_school_one_name !== "") {
      if (data.previous_school_one_year === "") {
        toast.error("The year for the first previous school is required");
        return false;
      } else if (data.previous_school_one_city === "") {
        toast.error(
          "The city or country for the first previous school is required"
        );
        return false;
      } else if (data.previous_school_one_class === "") {
        toast.error("The class for the first previous school is required");
        return false;
      }
    } else if (data.previous_school_two_name !== "") {
      if (data.previous_school_two_year === "") {
        toast.error("The year for the first previous school is required");
        return false;
      } else if (data.previous_school_two_city === "") {
        toast.error(
          "The city or country for the first previous school is required"
        );
        return false;
      } else if (data.previous_school_two_class === "") {
        toast.error("The class for the first previous school is required");
        return false;
      }
    } else if (data.previous_school_three_name !== "") {
      if (data.previous_school_three_year === "") {
        toast.error("The year for the first previous school is required");
        return false;
      } else if (data.previous_school_three_city === "") {
        toast.error(
          "The city or country for the first previous school is required"
        );
        return false;
      } else if (data.previous_school_three_class === "") {
        toast.error("The class for the first previous school is required");
        return false;
      }
    } else if (
      data.birth_certificate === "" ||
      data.birth_certificate === undefined ||
      data.birth_certificate === null
    ) {
      toast.error("Birth Certificate is required");
      return false;
    } else if (
      data.dob_day !== "" &&
      data.dob_month !== "" &&
      data.dob_year !== ""
    ) {
      // convert to date
      const date_str = `${data.dob_year}-${data.dob_month}-${data.dob_day}`;
      const date_of_birth = new Date(date_str);
      if (isNaN(date_of_birth)) {
        toast.error("Enter a valid date of birth");
        return false;
      } else {
        setData({ ...data, date_of_birth: date_str });
      }
    } else {
      return true;
    }
    return true;
  };

  const createPassportPicture = (file) => {
    try {
      const new_passport_picture = URL.createObjectURL(file);
      setPassportPicture(new_passport_picture);
    } catch (error) {
      fetch(file)
        .then((res) => res.blob())
        .then((blob) => {
          const another_file = new File([blob], "image", { type: blob.type });
          console.log(another_file);
          setPassportPicture(URL.createObjectURL(another_file));
        });
      setPassportPicture(file);
    }
  };

  return (
    <div className="admission-form-details">
      <div className="admission-form-info">
        <h3 className="form-info-title">Fill in your personal information</h3>
        <div className="form-info-description">
          <p>
            Fields marked as <span style={{ color: "red" }}>*</span> are
            required
          </p>
          <p>
            The names entered here must be same spelling and other as those used
            on official documents.
          </p>
        </div>
      </div>
      <form action="" className="main-admission-form">
        {/* PROFILE PICTURE */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Passport Picture <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <img
                style={{ height: "150px" }}
                src={passportPicture ? passportPicture : UserImage}
                alt="passport"
              />
              <input
                type="file"
                name="passport_picture"
                id="passport_picture"
                accept=".jpg,.png,.jpeg"
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.target.name]: e.target.files[0],
                  });
                  createPassportPicture(e.target.files[0]);
                }}
                style={{ display: "None" }}
              />
              <label htmlFor="passport_picture" className="upload-button">
                Upload Picture
              </label>
            </div>
          </div>
        </div>
        <p className="image-upload-description">
          Fill application form and attach your passport picture. The
          applicant’s passport picture should be in .jpg, .jpeg, .png or .gif
          format and must not exceed 100KB in size. Make sure you upload a
          picture showing your face clearly.
        </p>
        {/* SURNAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Surname(Last name) <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.surname}
                name="surname"
                id="surname"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* FIRST NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            First name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.first_name}
                name="first_name"
                id="first_name"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* MIDDLE NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">Middle name(Other names)</label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.other_name}
                name="other_name"
                id="other_name"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* GENDER */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Gender <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input radio-inputs">
              <div className="radio-input">
                <input
                  type="radio"
                  defaultChecked={data.gender === "MALE"}
                  name="gender"
                  id="gender_male"
                  value="MALE"
                  onChange={handleFormChange}
                />{" "}
                Male
              </div>
              <div className="radio-input">
                <input
                  type="radio"
                  defaultChecked={data.gender === "FEMALE"}
                  name="gender"
                  id="gender_female"
                  value="FEMALE"
                  onChange={handleFormChange}
                />{" "}
                Female
              </div>
            </div>
          </div>
        </div>
        {/* DATE OF BIRTH */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Date of Birth <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input date-of-birth">
              <select
                defaultValue={data.dob_day}
                name="dob_day"
                id="dob_day"
                onChange={handleFormChange}
              >
                <option value="">-Select Day-</option>
                {days.map((day) => (
                  <option value={day} key={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                defaultValue={data.dob_month}
                name="dob_month"
                id="dob_month"
                onChange={handleFormChange}
              >
                <option>-Select Month-</option>
                {months.map((month) => (
                  <option value={month.value} key={month.value}>
                    {month.name}
                  </option>
                ))}
              </select>
              <select
                name="dob_year"
                id=""
                defaultValue={data.dob_year}
                onChange={handleFormChange}
              >
                <option value="">-Select Year-</option>
                {years.map((year, index) => (
                  <option value={year} key={index}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* PLACE OF BIRTH */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">Place of birth</label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.place_of_birth}
                name="place_of_birth"
                id="place_of_birth"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* Nationality */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Nationality <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.nationality}
                name="nationality"
                id="nationality"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* HOMETOWN */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Hometown <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.hometown}
                name="hometown"
                id="hometown"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* REGION */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Region <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <select
                name="region"
                id="region"
                defaultValue={data.region}
                onChange={handleFormChange}
              >
                <option value="">-Select Region-</option>
                {regions.map((region) => (
                  <option value={region} key={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* HOME ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Home Address <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.home_address}
                name="home_address"
                id="home_address"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* HOME LANGUAGE */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Home Language <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.home_language}
                name="home_language"
                id="home_language"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* OTHER LANGUAGE */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">Other Language(s)</label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.other_language}
                name="other_language"
                id="other_language"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* RELIGION */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Religion <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.religion}
                name="religion"
                id="religion"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* APPLYING CLASS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            I am applying for class <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <select
                name="applying_class"
                defaultValue={data.applying_class}
                id="applying_class"
                onChange={handleFormChange}
              >
                <option value="">-Select Class-</option>
                <option value="Creche">Creche</option>
                <option value="Upper creche">Upper creche</option>
                <option value="Nursery 1">Nursery 1</option>
                <option value="Nursery 2">Nursery 2</option>
                <option value="Kindergarten 1">Kindergarten 1</option>
                <option value="Kindergarten 2">Kindergarten 2</option>
                <option value="Grade 1">Grade 1</option>
                <option value="Grade 2">Grade 2</option>
                <option value="Grade 3">Grade 3</option>
                <option value="Grade 4">Grade 4</option>
                <option value="Grade 5">Grade 5</option>
                <option value="Grade 6">Grade 6</option>
              </select>
            </div>
          </div>
        </div>
        {/* BIRTH CERTIFICATE */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Birth Certificate <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="file"
                accept=".png, .pdf, .jpeg, .jpg, .doc, .docx"
                name="birth_certificate"
                id="birth_certificate"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
              />
              <label className="upload-button" htmlFor="birth_certificate">
                {" "}
                <ImUpload3 /> Upload document
              </label>
              <p className="uploaded-file">
                {data.birth_certificate
                  ? data.birth_certificate.name
                  : "No file uploaded"}
              </p>
              <p className="file-upload-info">
                Click here to upload a copy of the birth certificate of the
                applicant. The picture should be readable, clear and visible.
              </p>
            </div>
          </div>
        </div>
        <h5 className="section-title">SCHOOL(S) ATTENDED</h5>
        {/* PREVIOUS SCHOOL (1) */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-multiple-inputs">
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_one_name}
                  name="previous_school_one_name"
                  id="previous_school_one_name"
                  onChange={handleFormChange}
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_one_year}
                  name="previous_school_one_year"
                  id="previous_school_one_year"
                  onChange={handleFormChange}
                  placeholder="Year"
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_one_city}
                  name="previous_school_one_city"
                  id="previous_school_one_city"
                  placeholder="City / Country"
                  onChange={handleFormChange}
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_one_class}
                  name="previous_school_one_class"
                  id="previous_school_one_class"
                  placeholder="Class"
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* PREVIOUS SCHOOL (2) */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-multiple-inputs">
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_two_name}
                  name="previous_school_two_name"
                  id="previous_school_two_name"
                  onChange={handleFormChange}
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_two_year}
                  name="previous_school_two_year"
                  id="previous_school_two_year"
                  onChange={handleFormChange}
                  placeholder="Year"
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_two_city}
                  name="previous_school_two_city"
                  id="previous_school_two_city"
                  placeholder="City / Country"
                  onChange={handleFormChange}
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_two_class}
                  name="previous_school_two_class"
                  id="previous_school_two_class"
                  placeholder="Class"
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* PREVIOUS SCHOOL(3) */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-multiple-inputs">
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_three_name}
                  name="previous_school_three_name"
                  id="previous_school_three_name"
                  onChange={handleFormChange}
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_three_year}
                  name="previous_school_three_year"
                  id="previous_school_three_year"
                  onChange={handleFormChange}
                  placeholder="Year"
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_three_city}
                  name="previous_school_three_city"
                  id="previous_school_three_city"
                  placeholder="City / Country"
                  onChange={handleFormChange}
                />
              </div>
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.previous_school_three_class}
                  name="previous_school_three_class"
                  id="previous_school_three_class"
                  placeholder="Class"
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* PREVIOUS REPORT CARD */}
        <div className="main-admission-form-row">
          <label htmlFor="">Previous Report Card</label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="file"
                accept=".png, .pdf, .jpeg, .jpg, .doc, .docx"
                name="previous_report_card"
                id="previous_report_card"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
              />
              <label className="upload-button" htmlFor="previous_report_card">
                {" "}
                <ImUpload3 /> Upload document
              </label>
              <p className="uploaded-file">
                {data.previous_report_card
                  ? data.previous_report_card.name
                  : "No file uploaded"}
              </p>
              <p className="file-upload-info">
                Click here to upload a copy of a previous report card of the
                applicant. The picture should be readable, clear and visible.
              </p>
            </div>
          </div>
        </div>
        <div className="form-bottom">
          <div className="empty-section"></div>
          <div className="form-save-buttons">
            
            <div className="save-button" onClick={() => saveAndProceed()}>
              Save &amp; Proceed
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StudentDetails;
