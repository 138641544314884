import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api_url } from "../../../../url";
import DashboardTopBar from "../../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";

const NewAssignment = () => {
  const navigations = ["Dashboard", "Academics", "Assignments"];
  const [assignmentData, setAssignmentData] = useState(null);
  const [creatingAssignment, setCreatingAssignment] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const handleFormChange = (e) => {
    setAssignmentData({
      ...assignmentData,
      [e.target.name]: e.target.value,
    });
  };

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubjects = async () =>{
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('access')}`
        }
    }
    await axios.get(`${api_url}academics/subjects/`, config).then((response)=>{
        setSubjects(response.data)
        
    }).catch((error)=>{
        
        
    })
}

  const createAssignment = async (e) => {
    e.preventDefault();
    setCreatingAssignment(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const formData = new FormData();

    Object.keys(assignmentData).forEach((key) => {
      formData.append(key, assignmentData[key]);
    });

    

    await axios
      .post(`${api_url}teacher/class-assignment/`, formData, config)
      .then((response) => {
        setCreatingAssignment(false);
        if(response.data?.success){
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Assignment Created Successfully",
          }).then(() => {
            window.location.reload()
          });
        }else{
          if (response?.data?.error) {
            for (const property in response?.data?.error) {
              if (property === "0") {
                toast.error(`${response?.data?.error[property]}`);
              } else {
                toast.error(
                  `${property} error: ${response?.data?.error[property]}`
                );
              }
            }
          } else {
            toast.error("Something unexpected happended. Please try again.");
          }
        }
      })
      .catch((error) => {
        
        setCreatingAssignment(false);
        toast.error("An unexpected error occurred. Please try again.");
        
        
      });
  };

  useEffect(() => {
    getClasses();
    getSubjects()
  }, []);

  return (
    <div>
      
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Add New Assignment</h3>
        </div>
        <form onSubmit={(e) => createAssignment(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Title</label>
              <input
                type="text"
                name="title"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Class*</label>
              <select
                onChange={(e) => handleFormChange(e)}
                name="school_class"
                id=""
                required
                className="custom-form-control"
              >
                <option value="">Select Class</option>
                {classes?.map((student_class, index) => (
                  <option value={student_class?.id} key={index}>
                    {student_class?.class_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Subject*</label>
              <select
                onChange={(e) => handleFormChange(e)}
                name="subject"
                id=""
                required
                className="custom-form-control"
              >
                <option value="">Select Subject</option>
                {subjects?.map((subject, index) => (
                  <option value={subject?.id} key={index}>
                    {subject?.subject_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="custom-form-row">
            

            <div className="custom-form-col">
              <label htmlFor="">Description</label>
              <CKEditor
                required
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                }}
                data={assignmentData?.description}
                onChange={(event, editor) => {
                  const text = editor.getData();
                  setAssignmentData({
                    ...assignmentData,
                    description: text,
                  });
                }}
              />
              
            </div>

            
          </div>
          <div className="custom-form-row">
            

            <div className="custom-form-col">
              <label htmlFor="">Due Date</label>
              <input
                type="date"
                name="due_date"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
              
            </div>

            <div className="custom-form-col">
              <label htmlFor="">File Attachment</label>
              <input
                type="file"
                name="attachment"
                className="custom-form-control"
                onChange={(e) => setAssignmentData({
                    ...assignmentData, [e.target.name]:e.target.files[0]
                })}
              />
            </div>
          </div>

          
          
          <div className="form-action-buttons">
            {creatingAssignment ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAssignment;
