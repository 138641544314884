import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TeacherSubjects from '../pages/PortalPages/TeacherPages/TeacherSubject/TeacherSubjects'
import DashboardTopBar from '../components/PortalComponents/DashboardTopBar/DashboardTopBar'
import DashboardNavbar from '../components/PortalComponents/DashboardNavbar/DashboardNavbar'
import EducationalMaterials from '../pages/PortalPages/TeacherPages/EducationalMaterials/EducationalMaterials'
import LibraryUploads from '../pages/PortalPages/TeacherPages/LibraryUploads/LibraryUploads'
import TeacherAnellMeet from '../pages/PortalPages/TeacherPages/TeacherAnellMeet'
import TeacherDashboard from '../pages/PortalPages/TeacherPages/TeacherDashboard'
import ClassAssignments from '../pages/PortalPages/TeacherPages/ClassAssignments.jsx/ClassAssignments'
import NewAssignment from '../pages/PortalPages/TeacherPages/ClassAssignments.jsx/NewAssignment'
import AssignmentDetails from '../pages/PortalPages/TeacherPages/ClassAssignments.jsx/AssignmentDetails'

const TeacherRoutes = () => {
  return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
        <Routes>
            <Route path="/dashboard" element={<TeacherDashboard />} />
            <Route path='/academics/subjects' element={<TeacherSubjects />} />
            <Route path="/academics/educational-materials" element={<EducationalMaterials />} />
            <Route path="/academics/class-assignments" element={<ClassAssignments />} />
            <Route path="/academics/class-assignments/new" element={<NewAssignment />} />
            <Route path="/academics/class-assignments/:assignment_id/details" element={<AssignmentDetails />} />
            <Route path="/library/uploads" element={<LibraryUploads />} />
            <Route path="/academics/anell-meet" element={<TeacherAnellMeet />} />
        </Routes>
    </div>
  )
}

export default TeacherRoutes