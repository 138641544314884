import React, { useState } from "react";
import "./AdmissionForm.scss";
import Navbar from "../../components/Navbar/Navbar";
import TopBar from "../../components/TopBar/TopBar";
import StudentDetails from "./StudentDetails";
import ParentDetails from "./ParentDetails";
import GuardianDetails from "./GuardianDetails";
import Payment from "./Payment";
import Preview from "./Preview";
import AdditionalInformation from "./AdditionalInformation";
import { useParams } from "react-router-dom";
import { api_url } from "../../url";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import Swal from "sweetalert2";

const AdmissionForm = () => {
  const { token } = useParams();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState(
    {
    application_token: tokenDetails?.id,
    passport_picture: null,
    applicant_title: localStorage.getItem('applicantTitle'),
    applicant_name: localStorage.getItem('applicantName'),
    surname: "",
    first_name: "",
    other_name: "",
    gender: "",
    date_of_birth: "",
    dob_day: "",
    dob_month: "",
    dob_year: "",
    place_of_birth: "",
    nationality: "",
    hometown: "",
    region: "",
    home_address: "",
    home_language: "",
    other_language: "",
    religion: "",
    applying_class: "",
    birth_certificate: null,
    previous_report_card: null,
    previous_school_one_name: "",
    previous_school_one_year: "",
    previous_school_one_city: "",
    previous_school_one_class: "",
    previous_school_two_name: "",
    previous_school_two_year: "",
    previous_school_two_city: "",
    previous_school_two_class: "",
    previous_school_three_name: "",
    previous_school_three_year: "",
    previous_school_three_city: "",
    previous_school_three_class: "",
    // PARENT ONE
    parent_one_name: "",
    parent_one_nationality: "",
    parent_one_relationship: "",
    parent_one_telephone: "",
    parent_one_home_address: "",
    parent_one_occupation: "",
    parent_one_email: "",
    parent_one_hometown: "",
    parent_one_company_name: "",
    parent_one_company_address: "",
    // PARENT TWO
    parent_two_name: "",
    parent_two_nationality: "",
    parent_two_relationship: "",
    parent_two_telephone: "",
    parent_two_home_address: "",
    parent_two_occupation: "",
    parent_two_email: "",
    parent_two_hometown: "",
    parent_two_company_name: "",
    parent_two_company_address: "",
    // GUARDIAN
    guardian_name: "",
    guardian_nationality: "",
    guardian_relationship_to_student: "",
    guardian_telephone: "",
    guardian_home_address: "",
    guardian_email: "",
    guardian_occupation: "",
    guardian_company_name: "",
    guardian_company_address: "",
    // ADDITIONAL INFORMATION
    medium: "",
    family_friend_medium: "",
    other_medium: "",
    why_anell: "",
    parent_instruction: "",
    medical_information: "",
  });

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // GET TOKEN DETAILS
  const getTokenDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    try {
      await axios
        .get(`${api_url}admissions/application-token/${token}/`, config)
        .then((response) => {
          // if (response.data["is_used"]) {
          //   toast.error("Token has already been used to make an application");
          //   window.location.href = "/admission/apply/";
          // } else {
          //   setData({...data, ["application_token"]:response.data.id})
          //   setTokenDetails(response.data);
          // }
          setData({...data, ["application_token"]:response.data.id})
          setTokenDetails(response.data);
        });
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
        window.location.href = "/admission/apply/";
      }
    }
  };

//   CHECK TO SEE IF THERE IS ALREADY SOME FORMS FILLED WITH THIS DATA
const admissionApplication = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    try {
      await axios
        .get(`${api_url}admissions/user-application/${token}/`, config)
        .then((response) => {
          if(response.data){
            if(response.data['application_completed']){
              Swal.fire({
                title:"Application Already Completed",
                text:"You have already completed and submitted your application.",
                icon:"info"
              }).then(()=>{
                window.location.href="/"
              })
              setData(response.data);
            }else{
              setData(response.data);
            }
          }
        });
    } catch (error) {
      
    }
  };

  useEffect(()=>{
    getTokenDetails()
    admissionApplication()
  },[])

  return (
    <div>
      <TopBar />
      <Navbar />
      <div id="admission-form">
        <div className="admission-form-tabs">
          {/* STUDENT DETAILS */}
          <div
            className={`${
              activeStep === 1
                ? "admission-form-tab admission-form-tab-active"
                : "admission-form-tab"
            }`}
          >
            <div className="number">1</div>
            <div className="admission-form-tab-name">Student Details</div>
          </div>
          {/* PARENT DETAILS */}
          <div
            className={`${
              activeStep === 2
                ? "admission-form-tab admission-form-tab-active"
                : "admission-form-tab"
            }`}
          >
            <div className="number">2</div>
            <div className="admission-form-tab-name">Parent Details</div>
          </div>
          {/* GUARDIAN DETAILS */}
          <div
            className={`${
              activeStep === 3
                ? "admission-form-tab admission-form-tab-active"
                : "admission-form-tab"
            }`}
          >
            <div className="number">3</div>
            <div className="admission-form-tab-name">Guardian Details</div>
          </div>

          {/*  ADDITIONAL INFO */}
          <div
            className={`${
              activeStep === 4
                ? "admission-form-tab admission-form-tab-active"
                : "admission-form-tab"
            }`}
          >
            <div className="number">4</div>
            <div className="admission-form-tab-name">
              Additional Information
            </div>
          </div>

          {/*  PAYMENT */}
          <div
            className={`${
              activeStep === 5
                ? "admission-form-tab admission-form-tab-active"
                : "admission-form-tab"
            }`}
          >
            <div className="number">5</div>
            <div className="admission-form-tab-name">Payment</div>
          </div>

          {/*  PREVIEW */}
          <div
            className={`${
              activeStep === 6
                ? "admission-form-tab admission-form-tab-active"
                : "admission-form-tab"
            }`}
          >
            <div className="number">6</div>
            <div className="admission-form-tab-name">Preview</div>
          </div>
        </div>
        {activeStep === 1 && (
          <StudentDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleFormChange={handleFormChange}
            setData={setData}
            data={data}
          />
        )}
        {activeStep === 2 && (
          <ParentDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleFormChange={handleFormChange}
            data={data}
          />
        )}
        {activeStep === 3 && (
          <GuardianDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleFormChange={handleFormChange}
            data={data}
          />
        )}
        {activeStep === 4 && (
          <AdditionalInformation
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleFormChange={handleFormChange}
            data={data}
            setData={setData}
          />
        )}
        {activeStep === 5 && (
          <Payment
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleFormChange={handleFormChange}
            data={data}
            token={tokenDetails}
          />
        )}
        {activeStep === 6 && (
          <Preview
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleFormChange={handleFormChange}
            token={tokenDetails}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default AdmissionForm;
