import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardNavigation from "../../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import Loading from "../../../../components/Loading/Loading";
import axios from "axios";
import { api_url } from "../../../../url";
import parse from "html-react-parser";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";

const AssignmentDetails = () => {
  const { assignment_id } = useParams();
  const [loadingAssignmentDetails, setLoadingAssignmentDetails] =
    useState(true);
  const [assignmentDetails, setAssignmentDetails] = useState({});
  const [submittingAssignment, setSubmittingAssignment] = useState(false);
  const [submissionData, setSubmissionData] = useState({});

  const navigations = ["Dashboard", "Academics", "Assignment Details"];

  const getAssignmentDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}teacher/class-assignment/${assignment_id}/`, config)
      .then((response) => {
        setLoadingAssignmentDetails(false);
        if (response.data?.success) {
          setAssignmentDetails(response.data?.payload);
        } else {
          toast.error("Failed to get assignments");
        }
      })
      .catch((error) => {
        toast.error("Something unexpected happened.");
      });
  };

  const submitAssignment = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const formData = new FormData();

    Object.keys(submissionData).forEach((key) => {
      formData.append(key, submissionData[key]);
    });

    await axios
      .put(`${api_url}student/assignments/${assignment_id}/`, formData, config)
      .then((response) => {
        setSubmittingAssignment(false);
        if (response.data?.success) {
          Swal.fire({
            title: "Assignment Submitted",
            text: "Your assignment has been submitted successfully",
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          if (response?.data?.error) {
            for (const property in response?.data?.error) {
              if (property === "0") {
                toast.error(`${response?.data?.error[property]}`);
              } else {
                toast.error(
                  `${property} error: ${response?.data?.error[property]}`
                );
              }
            }
          } else {
            toast.error("Something unexpected happended. Please try again.");
          }
        }
      })
      .catch((error) => {
        setSubmittingAssignment(false);
        toast.error("Something unexpected happened.");
      });
  };

  useEffect(() => {
    getAssignmentDetails();
  }, []);

  return (
    <div>
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Assignment Details</div>
        </div>
        {loadingAssignmentDetails ? (
          <Loading />
        ) : (
          <div>
            <div className="custom-form" style={{ marginBottom: "20px" }}>
              <div className="custom-form-row">
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Title
                  </label>
                  <p>{assignmentDetails?.title}</p>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Class
                  </label>
                  <p>{assignmentDetails?.school_class}</p>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Subject
                  </label>
                  <p>{assignmentDetails?.subject}</p>
                </div>
              </div>
              <div className="custom-form-row">
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Due Date
                  </label>
                  <p>{assignmentDetails?.due_date}</p>
                </div>
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    File Attachment
                  </label>
                  <Link
                    style={{ textDecoration: "underline", color: "blue" }}
                    to={`${api_url}${assignmentDetails?.attachment}`}
                    download
                    target="_blank"
                  >
                    Download File
                  </Link>
                </div>
                
              </div>
              <div className="custom-form-row">
                <div className="custom-form-col">
                  <label
                    style={{ fontWeight: 700, fontSize: "20px" }}
                    htmlFor=""
                  >
                    Description
                  </label>
                  {
                    assignmentDetails?.description && (
                        <p>
                            {parse(assignmentDetails?.description)}
                        </p>
                    )
                  }
                </div>
                
              </div>
            </div>
            <hr />
            {assignmentDetails?.assignment_submissions?.length > 0 ? (
              <div className="section-list">
              <div className="table-section-header">Assignment Submissions</div>
    
                <div className="section-list-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Student</th>
                        <th>Date Submitted</th>
                        
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignmentDetails?.assignment_submissions?.map((assignment, index) => (
                          <tr key={index}>
                            <td>{assignment?.student}</td>
                            <td>{assignment?.submission_date}</td>
                            
                            <td>
                             
                                <div className="action-buttons">
                                  <Link
                                    to={`${api_url}${assignment?.submission_file}`}
                                    className="view-button"
                                  >
                                    Download File
                                  </Link>
                                  
                                </div>
                             
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  
                  
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              </div>
            ):(
                <div>
                    <p>No submissions yet</p>
                </div>
            )
            }
            
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignmentDetails;
