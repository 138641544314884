import React from 'react'
import DashboardTopBar from '../components/PortalComponents/DashboardTopBar/DashboardTopBar'
import DashboardNavbar from '../components/PortalComponents/DashboardNavbar/DashboardNavbar'
import { Route, Routes } from 'react-router-dom'
import StudentLibrary from '../pages/PortalPages/StudentPages/StudentLibrary'
import StudentSubjects from '../pages/PortalPages/StudentPages/StudentSubjects'
import StudentAnellMeet from '../pages/PortalPages/StudentPages/StudentAnellMeet'
import StudentDashboard from '../pages/PortalPages/StudentPages/StudentDashboard'
import StudentAssignments from '../pages/PortalPages/StudentPages/StudentAssignments'
import StudentAssignmentDetails from '../pages/PortalPages/StudentPages/StudentAssignmentDetails'

const StudentRoutes = () => {
  return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
        <Routes>
            <Route path="/dashboard" element={<StudentDashboard />} />
            <Route path='/anell-library' element={<StudentLibrary />} />
            <Route path='/academics/subjects' element={<StudentSubjects />} />
            <Route path="/academics/anell-meet" element={<StudentAnellMeet />} />
            <Route path="/academics/assignments/" element={<StudentAssignments />} />
            <Route path="/academics/assignments/:assignment_id/details" element={<StudentAssignmentDetails />} />
        </Routes>
    </div>
  )
}

export default StudentRoutes