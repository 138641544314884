import React, { useEffect, useState } from "react";
import "./NewsSection.scss";
import parse from 'html-react-parser'
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../url";
import Loading from "../Loading/Loading";
import NoData from "../PortalComponents/NoData/NoData";
import { toast } from "react-toastify";

const NewsSection = () => {
  const [gettingNews, setGettingNews] = useState(true);
  const [news, setNews] = useState([]);

  const getNews = async () => {
    const config = {
      header:{
        'Content-Type': 'application/json'
      }
    }
    await axios.get(`${api_url}news/`, config).then((response)=>{
      
      setNews(response.data)
      setGettingNews(false);
    }).catch((error)=>{
      toast.error("Error in getting news. Please refresh page.")
      setGettingNews(false)
    })
  }

  useEffect(()=>{
    getNews();
  },[])

  
  return (
    <div id="news" className="main-container">
      {
        gettingNews ? (
          <Loading />
        ):(
          <div className="news-items">
            {news.map((newsItem, index) => (
              <div className="news-item" key={index}>
                <div
                  className="news-item-header"
                  style={{ backgroundImage: `url(http://anellapi.rxultimate.com${newsItem?.thumbnail})` }}
                >
                  {/* <img src={newsItem.image} alt="news-item" /> */}
                </div>
                <div className="news-item-body">
                  <div className="date-title">{newsItem.date}</div>
                  <div className="news-title">{newsItem.title}</div>
                  <div className="news-body">{parse(newsItem.details.slice(0,200))}...</div>
                  <Link to={`/news-events/news/${newsItem?.slug}`}>READ MORE</Link>
                </div>
              </div>
            ))}
          </div>
        )
      }
      {
        !gettingNews && news.length <= 0 && (
          <NoData />
        )
      }
      <div className="news-letter-section">
        <div className="news-letter-section-left">
          <h3>Newsletter</h3>
          <p>
          Stay in the know and be the first to hear about the latest news, trends, and exciting developments in your favorite niche! 
          </p>
        </div>
        <div className="news-letter-section-right">
          <form onSubmit={(e)=>e.preventDefault()}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email address here"
            />
            <input type="submit" value="SUBSCRIBE NOW" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
