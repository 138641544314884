import React, { useContext, useState } from "react";
import DashboardTopBar from "../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import AuthContext from "../../context/authContext";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { api_url } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const UserProfile = () => {
  const navigations = ["Dashboard", "Profile"];
  const { user } = useContext(AuthContext);
  const [updateData, setUpdateData] = useState({})
  const [updating, setUpdating] = useState(false)
  const [passwordData, setPasswordData] = useState({})
  const [changingPassword, setChangingPassword] = useState(false)

  const handleChange = (e) => {
    setUpdateData({...updateData, [e.target.name]: e.target.value });
  }

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]:e.target.value
    })
  }

  const changePassword = async (e) => {
    e.preventDefault()
    console.log(passwordData)
    if(passwordData.new_password !== passwordData.confirm_password){
      return toast.error("Passwords do not match");
    }
    setChangingPassword(true)
    const config = {
      headers: {  
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(passwordData)

    await axios
      .put(`${api_url}accounts/password-change/`, body,config)
      .then((response) => {
        setChangingPassword(false);
        if(response?.data?.success){
            Swal.fire({
                title: "Success",
                icon: "success",
                text: "Password Changed Successfully",
              }).then(() => {
                setChangingPassword(false);
                window.location.reload();
              });
        }else{
            if(response?.data?.errors){
                for (const property in response?.data?.errors) {
                    if (property === "0") {
                      toast.error(`${response?.data?.errors[property]}`);
                    } else {
                      toast.error(
                        `${property} error: ${response?.data?.errors[property]}`
                      );
                    }
                  }
            }else{
                toast.error("Something went wrong. Please try again later.");
            }
        }
      })
      .catch((error) => {
        setChangingPassword(false)
        toast.error("Something went wrong. Please try again later")
      });
  };

  const updateProfile = async (e) => {
    e.preventDefault()
    setUpdating(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(updateData)

    await axios
      .put(`${api_url}accounts/user-profile/`, body,config)
      .then((response) => {
        setUpdating(false);
        if(response?.data?.success){
            Swal.fire({
                title: "Success",
                icon: "success",
                text: "Profile Updated Successfully",
              }).then(() => {
                setUpdating(false);
                window.location.reload();
              });
        }else{
            if(response?.data?.errors){
                for (const property in response?.data?.errors) {
                    if (property === "0") {
                      toast.error(`${response?.data?.errors[property]}`);
                    } else {
                      toast.error(
                        `${property} error: ${response?.data?.errors[property]}`
                      );
                    }
                  }
            }else{
                toast.error("Something went wrong. Please try again later.");
            }
        }
      })
      .catch((error) => {
        setUpdating(false)
        toast.error("Something went wrong. Please try again later")
      });
  };
  
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Profile</h3>
        </div>
        <form onSubmit={(e)=>updateProfile(e)} className="custom-form" style={{marginBlock:"20px"}}>
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">First Name*</label>
              <input
                type="text"
                name="first_name"
                className="custom-form-control"
                value={updateData?.first_name ? updateData?.first_name : user?.first_name}
                required
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Last Name*</label>
              <input
                type="text"
                name="last_name"
                className="custom-form-control"
                required
                value={updateData?.last_name ? updateData?.last_name : user?.last_name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            
          </div>

          <div className="custom-form-row">
           
            <div className="custom-form-col">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                className="custom-form-control"
                
                value={updateData?.phone_number ? updateData?.phone_number : user?.phone_number}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="email"
                className="custom-form-control"
                
                value={updateData?.email ? updateData?.email : user?.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

         
          
          <div className="form-action-buttons">
            {updating ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Save
              </button>
            )}
          </div>
        </form>
        <hr />
        <h3 className="page-title">Reset Password</h3>
        <form onSubmit={(e)=>{changePassword(e)}} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">New Password</label>
              <input
                type="password"
                name="new_password"
                className="custom-form-control"
                required
                onChange={(e) => handlePasswordChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Confirm Password</label>
              <input
                type="password"
                name="confirm_password"
                className="custom-form-control"
                required
                onChange={(e) => handlePasswordChange(e)}
              />
            </div>
          </div>
          <div className="form-action-buttons">
            {changingPassword ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Change Password
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
