import React, { useState, useEffect } from 'react'
import Loading from '../../../components/Loading/Loading';
import NoData from '../../../components/PortalComponents/NoData/NoData';
import axios from 'axios';
import { api_url } from '../../../url';
import { toast } from 'react-toastify';
import DashboardNavigation from '../../../components/PortalComponents/DashboardNavigation/DashboardNavigation';
import { Link } from 'react-router-dom';

const StudentAssignments = () => {
    const [loadingAssignments, setLoadingAssignments] = useState(true);
  const [pendingAssignments, setPendingAssignments] = useState([]);
  const [submittedAssignments, setSubmittedAssignments] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const navigations = ["Dashboard", "Academics", "Assignments"];

  const getAssignments = async () => {
    const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
    };
    await axios.get(`${api_url}student/assignments/`, config).then((response)=>{
        setLoadingAssignments(false)
        if(response.data?.success){
            setPendingAssignments(response.data?.pending_assignments)
            setSubmittedAssignments(response.data?.submitted_assignments)
        }else{
            toast.error("Failed to get assignments")
        }
    }).catch((error)=>{
        toast.error("Something unexpected happened.")
    })
  }

  useEffect(()=>{
    getAssignments();
  },[])

  return (
    <div>
      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Assignments</div>
          <div
            className="custom-tabs"
            style={{ borderBottom: "1px solid black" }}
          >
            <div
              onClick={() => setActiveTab(1)}
              className={`custom-tab-section ${activeTab === 1 && "active"}`}
            >
              Pending Assignments
            </div>
            <div
              onClick={() => setActiveTab(2)}
              className={`custom-tab-section ${activeTab === 2 && "active"}`}
            >
              Submitted Assignments
            </div>
          </div>
          {loadingAssignments ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              {activeTab === 1 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Assignment Title</th>
                        <th>Date assigned</th>
                        <th>Assigned By</th>
                        <th>Subject</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingAssignments &&
                        pendingAssignments?.map((assignment, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{assignment?.class_assignment?.title}</td>
                            <td>{assignment?.class_assignment?.created_at}</td>
                            <td>{assignment?.class_assignment?.uploaded_by}</td>
                            <td>{assignment?.class_assignment?.subject}</td>
                            <td style={{ cursor: "pointer" }}>
                              <div className="action-buttons">
                                <Link
                                  to={`/student/academics/assignments/${assignment?.id}/details`}
                                  className="view-button"
                                >
                                  View Details
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {pendingAssignments?.length <= 0 && <NoData />}
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Assignment Title</th>
                        <th>Date assigned</th>
                        <th>Assigned By</th>
                        <th>Subject</th>
                        <th>Action</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {submittedAssignments &&
                        submittedAssignments?.map((assignment, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{assignment?.assignment_name}</td>
                            <td>{assignment?.date}</td>
                            <td>{assignment?.time}</td>
                            <td>{assignment?.created_by}</td>
                            
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {submittedAssignments?.length <= 0 && <NoData />}
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              )}
              <></>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StudentAssignments