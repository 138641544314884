import React, { useContext, useEffect, useState } from "react";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import { Link } from "react-router-dom";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { api_url } from "../../../url";
import { RotatingLines } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import Loading from "../../../components/Loading/Loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AuthContext from "../../../context/authContext";

const StudentsList = () => {
  const {schoolClasses} = useContext(AuthContext)
  const [students, setStudents] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [gettingStudents, setGettingStudents] = useState(true);
  const [deletingStudent, setDeletingStudent] = useState(false);
  const [filterClass, setFilterClass] = useState("")
  const navigations = ["Dashboard", "User Management", "Student List"];

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = studentsList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(studentsList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % studentsList.length;
    setItemOffset(newOffset);
  };

  const getStudentList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}accounts/student`, config)
      .then((response) => {
        setStudents(response?.data);
        setStudentsList(response.data);
        setGettingStudents(false);
      })
      .catch((error) => {
        
        setGettingStudents(false);
      });
  };

  const filterStudentList = async (e) => {
    e.preventDefault()
    if(filterClass === ""){
      return toast.error("Please select a class")
    }
    setGettingStudents(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}accounts/filter/student/${filterClass}`, config)
      .then((response) => {
        setStudents(response?.data);
        setStudentsList(response.data);
        setGettingStudents(false);
      })
      .catch((error) => {
        
        setGettingStudents(false);
      });
  };

  const deleteStudent = (student_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingStudent(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}accounts/student/${student_id}/`, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Student Deleted Successfully",
            }).then(() => {
              setDeletingStudent(false);
              getStudentList();
            });
          })
          .catch((error) => {
            setDeletingStudent(false);

            toast.error(
              "There was an error deleting the student, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getStudentList();
  }, []);
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        {gettingStudents ? (
          <Loading />
        ) : (
          <div className="section-list">
            <div className="table-section-header">Students</div>
            <div style={{ display: "flex", gap: "5px", marginBottom: "10px" }}>
              <Link
                to="/admin/user-management/students/new"
                className="primary-button-outline w-max-content"
                style={{ marginBlock: "5px" }}
              >
                Add New Student
              </Link>
              <Link
                to="/admin/user-management/students/multiple"
                className="primary-button-outline w-max-content"
                style={{ marginBlock: "5px" }}
              >
                Upload Multiple Students
              </Link>
            </div>
            <form
              onSubmit={(e) => filterStudentList(e)}
              className="filter-group"
            >
              <select
                name="school_class"
                onChange={(e) => setFilterClass(e.target.value)}
                id=""
                className="form-item"
              >
                <option value="">Select Class</option>
                
                {schoolClasses?.map((school_class, index) => (
                    <option key={index} value={school_class.id}>
                      {school_class.class_name}
                    </option>
                  ))}
              </select>

              {gettingStudents ? (
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              ) : (
                <button className="primary-button" type="submit">
                  Filter
                </button>
              )}
            </form>

            <div className="section-list-table">
              <table>
                <thead>
                  <tr>
                    <th>Student ID</th>
                    <th>Username</th>
                    <th>Student Name</th>
                    <th>Student Class</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {studentsList &&
                    currentItems.map((student, index) => (
                      <tr key={index}>
                        <td>{student?.student_id}</td>
                        <td>{student?.username}</td>
                        <td>{student?.student_name}</td>
                        <td>{student?.student_class}</td>
                        <td>
                          {deletingStudent ? (
                            <RotatingLines
                              strokeColor="#021D53"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="26"
                              visible={true}
                            />
                          ) : (
                            <div className="action-buttons">
                              <Link
                                to={`/admin/user-management/students/${student?.id}/details`}
                                className="view-button"
                              >
                                View <AiOutlineEye />
                              </Link>
                              <Link
                                onClick={() => deleteStudent(student?.id)}
                                className="delete-button"
                              >
                                Delete <AiOutlineDelete />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {gettingStudents && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RotatingLines
                    strokeColor="#021D53"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="26"
                    visible={true}
                  />
                </div>
              )}
              {!gettingStudents && studentsList.length <= 0 && <NoData />}
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  activeClassName="active-page"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentsList;
