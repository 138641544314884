import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api_url } from '../../../url';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import { RotatingLines } from 'react-loader-spinner';
import DashboardNavbar from '../../../components/PortalComponents/DashboardNavbar/DashboardNavbar';
import DashboardTopBar from '../../../components/PortalComponents/DashboardTopBar/DashboardTopBar';
import DashboardNavigation from '../../../components/PortalComponents/DashboardNavigation/DashboardNavigation';

const StaffDetail = () => {
    const {staff_id} = useParams()
    const navigations = ["Dashboard", "User Management", "Staff Details"];
    const [staffDetails, setStaffDetails] = useState({})
    const [gettingStaffDetails, setGettingStaffDetails] = useState(true)
    const [staffUpdateData, setStaffUpdateData] = useState({})
    const [updatingStaffDetails, setUpdatingStaffDetails] = useState(false)

    const handleFormChange = (e) => {
        setStaffUpdateData({
          ...staffUpdateData,
          [e.target.name]: e.target.value,
        });
      };

      const getStaffDetails = async () => {
        

        const config = {
            headers:{
                'Content-Type':'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        }
        
        await axios.get(`${api_url}accounts/employee/${staff_id}/`, config).then((response)=>{
            
            setStaffDetails(response.data)
            setGettingStaffDetails(false)
        }).catch((error)=>{
            toast.error("something went wrong")
            setGettingStaffDetails(false)
        })
    }

    const updateStaff = async (e) => {
        e.preventDefault();
        setUpdatingStaffDetails(true)
        const config = {
            headers:{
                'Content-Type':'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        }
        const body = JSON.stringify(staffUpdateData)
        await axios.put(`${api_url}accounts/employee/${staff_id}/`, body, config).then((response)=>{
            Swal.fire({
                title:"Success",
                icon:"success",
                text:"Staff Details Updated Successfully"
            })
            setUpdatingStaffDetails(false)
        }).catch((error)=>{
            setUpdatingStaffDetails(false)
            toast.error("something went wrong")
        })
    }

    useEffect(()=>{
      getStaffDetails();
    },[])
  return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
        {gettingStaffDetails ? <Loading /> :
        (
            <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Add New Staff</h3>
        </div>
        <form onSubmit={(e) => updateStaff(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Employee ID</label>
              <input
                type="text"
                name="employee_id"
                className="custom-form-control"
                value={staffUpdateData?.employee_id ? staffUpdateData?.employee_id : staffDetails?.employee_id}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name="first_name"
                className="custom-form-control"
                value={staffUpdateData?.first_name ? staffUpdateData?.first_name : staffDetails?.first_name}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name="last_name"
                className="custom-form-control"
                value={staffUpdateData?.last_name ? staffUpdateData?.last_name : staffDetails?.last_name}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Position</label>
              <select
                onChange={(e) => handleFormChange(e)}
                name="position"
                id=""
                className="custom-form-control"
              >
                <option value="">Select Position</option>
                <option selected={staffDetails?.position === "Teacher"} value="Teacher">Teacher</option>
                <option selected={staffDetails?.position === "Assistant Administrator"} value="Assistant Administrator">Assistant Administrator</option>
                <option selected={staffDetails?.position === "Head Of Academics"} value="Head Of Academics">Head Of Academics</option>
              </select>
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                className="custom-form-control"
                value={staffUpdateData?.phone_number ? staffUpdateData?.phone_number : staffDetails?.phone_number}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Nationality</label>
              <input
                type="text"
                name="nationality"
                className="custom-form-control"
                value={staffUpdateData?.nationality ? staffUpdateData?.nationality : staffDetails?.nationality}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Marital Status</label>
              <select
                name="marital_status"
                onChange={(e) => handleFormChange(e)}
                id=""
                className="custom-form-control"
              >
                <option value="">Select Marital Status</option>
                <option selected={staffDetails?.marital_status === "Single" } value="Single">Single</option>
                <option selected={staffDetails?.marital_status === "Married" } value="Married">Married</option>
                <option selected={staffDetails?.marital_status === "Divorced" } value="Divorced">Divorced</option>
                <option selected={staffDetails?.marital_status === "Widowed" } value="Widowed">Widowed</option>
              </select>
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Date of birth</label>
              <input
                type="date"
                name="date_of_birth"
                className="custom-form-control"
                value={staffUpdateData?.date_of_birth ? staffUpdateData?.date_of_birth : staffDetails?.date_of_birth}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Hometown</label>
              <input
                type="text"
                name="hometown"
                className="custom-form-control"
                value={staffUpdateData?.hometown ? staffUpdateData?.hometown : staffDetails?.hometown}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          
          <div className="form-action-buttons">
            {updatingStaffDetails ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Save
              </button>
            )}
          </div>
        </form>
      </div>
        )
        
        }
    </div>
  )
}

export default StaffDetail