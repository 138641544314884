import React from "react";
import Navbar from "../Navbar/Navbar";
import TopBar from "../TopBar/TopBar";
import Footer from "../Footer/Footer";
import "./NewsItem.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import parse from 'html-react-parser'

import { BsWhatsapp } from "react-icons/bs";
import { api_url } from "../../url";
import axios from "axios";
import Loading from "../Loading/Loading";

const NewsItem = () => {
  const location = useLocation();
  const { slug } = useParams();
  const [gettingNews, setGettingNews] = useState(true)
  const [news, setNews] = useState([])
  const [mainNews, setMainNews] = useState({});

  
  
  const getNews = async () => {
    const config = {
      header:{
        'Content-Type': 'application/json'
      }
    }
    await axios.get(`${api_url}news/`, config).then((response)=>{
      console.log(response.data)
      setNews(response.data)
      setMainNews(response.data?.find((newsItem) => newsItem.slug === slug));
      setGettingNews(false);
    }).catch((error)=>{
      console.error(error)
      setGettingNews(false)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getNews()
    
  }, [location]);

  const selectMainNews = (news_slug) => {
    setMainNews(news.find((newsItem) => newsItem.slug === news_slug));
  };

  return (
    <div>
      <TopBar />
      <Navbar />
      {
        gettingNews ? (
          <Loading />
        ):(
          <div id="news-item-section">
            <div className="main-news">
              <h1 className="news-item-title">{mainNews?.title}</h1>
              <p className="news-item-description">{mainNews?.date}</p>
              <div
                className="news-item-main-image"
                style={{
                  backgroundImage: `url(http://anellapi.rxultimate.com${mainNews?.thumbnail})`,
                  backgroundPositionX: "center",
                  backgroundPositionY: "center",
                  height: "500px",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={mainNews?.image} alt="graduation" /> */}
              </div>
              <p className="news-item-body">
                {parse(mainNews?.details)}
              </p>
              <div className="event-images">
                {mainNews?.images?.map((image, index) => (
                  <div key={index} className="event-image">
                    <img src={`http://anellapi.rxultimate.com${image?.image}`} alt="" loading="lazy" />
                  </div>
                ))}
              </div>
            </div>
            <div className="recent-posts">
              <h2 className="recent-posts-title">Recent Posts</h2>
              <ul className="recent-posts-list">
                {news.map((newsItem, index) => (
                  <li
                    onClick={() => selectMainNews(newsItem.slug)}
                    key={index}
                    className="recent-post"
                  >
                    <Link to={`/news-events/news/${newsItem.slug}`}>
                      {newsItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )
      }
      <Link
        to="https://wa.me/2330504828824?text="
        target="_blank"
        id="whatsapp-icon"
      >
        <BsWhatsapp />
      </Link>
      <Footer />
    </div>
  );
};

export default NewsItem;
