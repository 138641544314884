import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../../url";

const Sharefile = () => {
  const { file_id } = useParams();
  const [sharingFile, setSharingFile] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([])
  const [data, setData] = useState({
    file: file_id,
  });
  const navigations = ["Dashboard", "File manager", "Share file"];

  // HANDLE CHECK ALL CHECK BOXES
  const handleCheckboxChange = (e) => {
    let selectedStaffList = selectedStaffs;

    if (e.target.checked) {
      selectedStaffList = [...selectedStaffList, e.target.value];
    }else{
      selectedStaffList.splice(selectedStaffList.indexOf(e.target.value), 1);
    }
    setSelectedStaffs(selectedStaffList)
  };

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const sharefile = async (e) => {
    e.preventDefault();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const body = JSON.stringify({
      ...data, "selected_users":selectedStaffs
    });
    setSharingFile(true);
    await axios
      .post(`${api_url}filemanager/shared-files/`, body, config)
      .then((response) => {
        setSharingFile(false);
        if (response?.data?.success) {
          toast.success("File shared successfully");
        } else {
          toast.error(response.data?.message);
        }
      })
      .catch((error) => {
        setSharingFile(false);
        toast.error("something went wrong");
      });
  };

  const getStaffList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}accounts/employee`, config)
      .then((response) => {
        setStaffList(response.data);
      })
      .catch((error) => {});
  };

  useEffect(()=>{
    getStaffList()
  },[])

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <h3 className="page-title">Share File</h3>
        <form onSubmit={(e) => sharefile(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Share file with</label>
              <select
                onChange={(e) => handleFormChange(e)}
                name="shared_with"
                id=""
                required
                className="custom-form-control"
              >
                <option value="">Share with</option>
                <option value="All Staff">All Staff</option>
                <option value="Specific Staff">Specific Staff</option>
              </select>
            </div>
          </div>
          {data.shared_with === "Specific Staff" && (
            <div className="section-list" style={{ marginTop: "20px" }}>
              <div className="table-section-header">Select Staffs</div>
              <div className="section-list-table">
                <>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Staff ID</th>
                        <th>Staff Name</th>

                        <th>Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffList?.map((staff, index) => (
                        <tr key={index}>
                          <td>
                          <input
                                type="checkbox"
                                name="staff"
                                value={staff?.user_account}
                                onChange={(e) => handleCheckboxChange(e)}
                              />
                          </td>
                          <td>{staff?.employee_id}</td>
                          <td>{staff?.staff_name}</td>
                          <td>{staff?.position}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              </div>
            </div>
          )}
          <div className="form-action-buttons">
            {sharingFile ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Share
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Sharefile;
