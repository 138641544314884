import React, { useState, useEffect } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";

import axios from "axios";
import { api_url } from "../../../url";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import ReactPaginate from "react-paginate";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [loadingApplications, setLoadingApplications] = useState(false);
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = applications.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(applications.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % applications.length;
    setItemOffset(newOffset);
  };

  // GET APPLICATIONS
  const getApplications = async () => {
    if (localStorage.getItem("access")) {
      setLoadingApplications(true);
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .get(`${api_url}admissions/applications/`, config)
          .then((response) => {
            setLoadingApplications(false);
            console.log(response.data)
            setApplications(response.data);
          });
      } catch (error) {
        setLoadingApplications(false);
        toast.error("An error occurred.");
      }
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  const navigations = [
    "Dashboard", "Admissions", "Applications"
  ]

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="admission-applications" className="portal-page">
        <div className="admin-dashboard-title">
        <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list">
          <div className="table-section-header">Admissions</div>
          {loadingApplications ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              {applications.length > 0 ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Application Date</th>
                        <th>Application Token</th>
                        <th>Applicant Name</th>
                        <th>D.O.B</th>
                        <th>Class</th>
                        <th>Phone Number</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((application) => (
                        <tr>
                          <td>{application.date_submitted}</td>
                          <td>{application.application_token}</td>
                          <td>
                            {application.first_name} {application.surname}{" "}
                            {application.other_name !== "null" &&
                              application.other_name}
                          </td>
                          <td>{application.date_of_birth}</td>
                          <td>{application.applying_class}</td>
                          <td></td>
                          <td>
                            <Link
                              to={`/admin/dashboard/admissions/applications/${application.id}`}
                              className="view-button"
                            >
                              View <AiOutlineEye />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div>
                </>
              ):(
                <p style={{testAlign: 'center'}}>No applications made  </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Applications;
