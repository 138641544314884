import React, { useEffect, useState } from "react";
import DashboardNavigation from "../../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../../../url";
import Loading from "../../../../components/Loading/Loading";
import NoData from "../../../../components/PortalComponents/NoData/NoData";
import { RotatingLines } from "react-loader-spinner";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ClassAssignments = () => {
  const navigations = ["Dashboard", "Academics", "Class Assignments"];
  const [loadingAssignments, setLoadingAssignments] = useState(false);
  const [assignements, setAssignments] = useState([]);
  const [deletingAssignment, setDeletingAssignment] = useState(false);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = assignements.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(assignements.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % assignements.length;
    setItemOffset(newOffset);
  };

  const deleteAssignment = async (assignment_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingAssignment(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
        .delete(`${api_url}teacher/class-assignment/${assignment_id}/`, config)
        .then((response) => {
          if(response?.data?.success){
            Swal.fire({
              icon: "success",
              title: "Deleted",
              text: "Assignment deleted successfully",
            }).then(() => {
              window.location.reload();
            })
          }else{
            if(response?.data?.message){
              toast.error(response?.data?.message)
            }else{
              toast.error("Something went wrong. Please try again later")
            }
          }
          setDeletingAssignment(false)
          
        }).catch(error => {
          setDeletingAssignment(false);
          toast.error("Something went wrong. Please try again later.")
        }); 
      }
    });
    

    
  };


  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  const getAssignments = async () => {
    setLoadingAssignments(true);
    await axios
      .get(`${api_url}teacher/class-assignment/`, config)
      .then((response) => {
        setLoadingAssignments(false);
        if (response.data?.success) {
          setAssignments(response?.data?.payload);
        }
      })
      .catch((error) => {
        setLoadingAssignments(false);
      });
  };

  useEffect(() => {
    getAssignments();
  }, []);

  return (
    <div id="school-classes" className="portal-page">
      <div className="admin-dashboard-title">
        <DashboardNavigation navigations={navigations} />
      </div>
      {loadingAssignments ? (
        <Loading />
      ) : (
        <div className="section-list ">
          <div className="table-section-header">Class Assignments</div>

          <div style={{ display: "flex", gap: "5px", marginBottom: "10px" }}>
            <Link
              to="/teacher/academics/class-assignments/new"
              className="primary-button-outline w-max-content"
              style={{ marginBlock: "5px" }}
            >
              Add New Assignment
            </Link>
         </div>

            <div className="section-list-table">
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Class</th>
                    <th>Subject</th>
                    <th>Date created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {assignements &&
                    currentItems.map((assignment, index) => (
                      <tr key={index}>
                        <td>{assignment?.title}</td>
                        <td>{assignment?.school_class}</td>
                        <td>{assignment?.subject}</td>
                        <td>{assignment?.created_at}</td>
                        <td>
                          {deletingAssignment ? (
                            <RotatingLines
                              strokeColor="#021D53"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="26"
                              visible={true}
                            />
                          ) : (
                            <div className="action-buttons">
                              <Link
                                to={`/teacher/academics/class-assignments/${assignment?.id}/details`}
                                className="view-button"
                              >
                                View <AiOutlineEye />
                              </Link>
                              <Link className="delete-button" onClick={()=>deleteAssignment(assignment?.id)}>
                                Delete <AiOutlineDelete />
                              </Link>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {loadingAssignments && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RotatingLines
                    strokeColor="#021D53"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="26"
                    visible={true}
                  />
                </div>
              )}
              {!loadingAssignments && assignements.length <= 0 && <NoData />}
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  activeClassName="active-page"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          </div>
        
      )}
    </div>
  );
};

export default ClassAssignments;
