import React, { useEffect, useState } from "react";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import Loading from "../../../components/Loading/Loading";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import ReactPaginate from "react-paginate";
import { LuDownload } from "react-icons/lu";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../../url";
import { Link } from "react-router-dom";

const StudentAnellMeet = () => {
  const [loadingMeetings, setLoadingMeetings] = useState(true);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [pastMeetings, setPastMeetings] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const navigations = ["Dashboard", "Academics", "Anell Meet"];

  const getMeetings = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}anell-meet/student/meetings/`, config)
      .then((response) => {
        if (response?.data?.success) {
          setUpcomingMeetings(response.data?.payload?.upcoming_meetings);
          setPastMeetings(response.data?.payload?.past_meetings);
        } else {
          if (response?.data?.message) {
            toast.error(response?.data?.message);
          } else {
            toast.error("Something unexpected happened");
          }
        }
        setLoadingMeetings(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");

        setLoadingMeetings(false);
      });
  };

  useEffect(() => {
    getMeetings();
  }, []);

  return (
    <div>
      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Anell Meet</div>
          <div
            className="custom-tabs"
            style={{ borderBottom: "1px solid black" }}
          >
            <div
              onClick={() => setActiveTab(1)}
              className={`custom-tab-section ${activeTab === 1 && "active"}`}
            >
              Upcoming Meetings
            </div>
            <div
              onClick={() => setActiveTab(2)}
              className={`custom-tab-section ${activeTab === 2 && "active"}`}
            >
              Past Meetings
            </div>
          </div>
          {loadingMeetings ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              {activeTab === 1 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Meeting Name</th>
                        <th>Meeting Date</th>
                        <th>Meeting Time</th>
                        <th>Uploaded By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingMeetings &&
                        upcomingMeetings?.map((meeting, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{meeting?.meeting_name}</td>
                            <td>{meeting?.date}</td>
                            <td>{meeting?.time}</td>
                            <td>{meeting?.created_by}</td>
                            <td style={{ cursor: "pointer" }}>
                              <div className="action-buttons">
                                <Link
                                  target="_blank"
                                  to={meeting?.meeting_link}
                                  className="view-button"
                                >
                                  Join Meeting
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {upcomingMeetings?.length <= 0 && <NoData />}
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Meeting Name</th>
                        <th>Meeting Date</th>
                        <th>Meeting Time</th>
                        <th>Uploaded By</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {pastMeetings &&
                        pastMeetings?.map((meeting, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{meeting?.meeting_name}</td>
                            <td>{meeting?.date}</td>
                            <td>{meeting?.time}</td>
                            <td>{meeting?.created_by}</td>
                            
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {pastMeetings?.length <= 0 && <NoData />}
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              )}
              <></>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentAnellMeet;
